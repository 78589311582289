var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Button content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeButtonContent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("<b-pagination-nav>")]),
        _c("span", [
          _vm._v(
            " supports several props/slots that allow you to customize the appearance."
          )
        ])
      ]),
      _c("b-pagination-nav", {
        attrs: {
          "number-of-pages": "10",
          "base-url": "#",
          "first-text": "First",
          "prev-text": "Prev",
          "next-text": "Next",
          "last-text": "Last"
        }
      }),
      _c("b-pagination-nav", {
        staticClass: "mt-2",
        attrs: {
          "number-of-pages": "10",
          "base-url": "#",
          "first-text": "⏮",
          "prev-text": "⏪",
          "next-text": "⏩",
          "last-text": "⏭"
        }
      }),
      _c("b-pagination-nav", {
        staticClass: "mt-2",
        attrs: { "number-of-pages": "10", "base-url": "#" },
        scopedSlots: _vm._u([
          {
            key: "first-text",
            fn: function() {
              return [
                _c("span", { staticClass: "text-success" }, [_vm._v("First")])
              ]
            },
            proxy: true
          },
          {
            key: "prev-text",
            fn: function() {
              return [
                _c("span", { staticClass: "text-danger" }, [_vm._v("Prev")])
              ]
            },
            proxy: true
          },
          {
            key: "next-text",
            fn: function() {
              return [
                _c("span", { staticClass: "text-warning" }, [_vm._v("Next")])
              ]
            },
            proxy: true
          },
          {
            key: "last-text",
            fn: function() {
              return [
                _c("span", { staticClass: "text-info" }, [_vm._v("Last")])
              ]
            },
            proxy: true
          },
          {
            key: "ellipsis-text",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("b-spinner", { attrs: { small: "", type: "grow" } }),
                    _c("b-spinner", { attrs: { small: "", type: "grow" } })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "page",
            fn: function(ref) {
              var page = ref.page
              var active = ref.active
              return [
                active
                  ? _c("b", [_vm._v(_vm._s(page))])
                  : _c("i", [_vm._v(_vm._s(page))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }