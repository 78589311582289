var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Providing an array of pages" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeArray) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Rather than using ")]),
        _c("code", [_vm._v("number-of-pages")]),
        _c("span", [
          _vm._v(
            " to auto generate page links, you can pass an array of links via the "
          )
        ]),
        _c("code", [_vm._v("pages")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c("b-pagination-nav", {
        attrs: { pages: _vm.pages1, "use-router": "" }
      }),
      _c("b-pagination-nav", {
        staticClass: "mt-2",
        attrs: { pages: _vm.pages2, "use-router": "" }
      }),
      _c("b-pagination-nav", {
        staticClass: "mt-2",
        attrs: { pages: _vm.pages3, "use-router": "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }